import { useState } from 'react'
import ContactModal from 'observian-ui/lib/modal/contact'
import Button from 'observian-ui/lib/button'
import { buildFormUrl } from '../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'

import GoldPartner from '../../../../static/images/datadog/goldpartner.png'
import checkmark from '../../../../static/images/datadog/checkmark.svg'
import hexagon from '../../../../static/images/datadog/background-hexagon.svg'
import bars from '../../../../static/images/datadog/bars.svg'

import style from './hero.module.css'

const Hero = () => {
  const [modal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return (
    <section className={style.hero}>
      <div className={style.heroContainer}>
        <div className={style.heroTextLeft}>
          <h2 className={style.headerText}>WE KNOW OBSERVABILITY AND SECURITY…</h2>
          <p className={style.description}>
            Observian is a Datadog Gold Tier Managed Services Provider, helping customers with
            managed services and all things Datadog. Whether your business is exploring Datadog, or
            you have a seasoned Datadog team - Observian can help save money and help you realize
            increased value from your toolset.
          </p>
          <p className={style.description}>
            Datadog Cloud SIEM elevates your organization’s threat detection and investigation for
            their dynamic, cloud-scale environments. With Cloud SIEM, you can augment your existing
            SIEM investments and deliver better cloud security outcomes. Cloud SIEM analyzes
            operational and security logs in real time—regardless of their volume—while utilizing
            curated, out-of-the-box integrations and rules to detect threats and investigate them.
            Developers, security, and operations teams can also leverage detailed observability data
            to accelerate security investigations in a single, unified platform.
          </p>
          <p className={style.description}>
            Observian can help you get started with Datadog Cloud SIEM.
          </p>
          <Button
            className={style.button}
            label="Let's Talk about Datadog Cloud SIEM"
            onClick={() =>
              (window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: 'Datadog - Let\'s Talk about Datadog Cloud SIEM',
                },
              ]))
            }
          />
        </div>
        <div className={style.heroRight}>
          <img className={style.goldPartner} src={GoldPartner} alt="datadog-gold-partner" />
          <div className={style.featuresLine}>
            <img className={style.checkmark} src={checkmark} alt="datadog-checkmark" />
            <h3 className={style.descriptionPoints}>DISCOUNTED LICENSING</h3>
          </div>
          <div className={style.featuresLine}>
            <img className={style.checkmark} src={checkmark} alt="datadog-checkmark" />
            <h3 className={style.descriptionPoints}>FULLY MANAGED OPTIONS</h3>
          </div>
          <div className={style.featuresLine}>
            <img className={style.checkmark} src={checkmark} alt="datadog-checkmark" />
            <h3 className={style.descriptionPoints}>BILLING/SPEND ALERTS</h3>
          </div>
        </div>
      </div>
      <img className={style.hexagon} src={hexagon} alt="background-hexagon" />
      <img className={style.bars} src={bars} alt="background-bars" />
      {modal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={modal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact"
            buttonLabel="Send"
            isLandingPage
            ctaIntentSuffix="modal-datadog-contact-top-cta"
            inquiryPlaceholder="I'd like to see how Observian and Datadog can help my business."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default Hero
